import React from "react";

const HomeProjects = () => (
<div className="grid projectsgrid">
    <div className="griditem vertical">
        <a className="gridProjectLink" href="https://www.slotscalendar.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="slotscalendar">
            <img src="img/projects/slotscalendar/logo.svg" alt="slotscalendar" loading="lazy" />
        </a>
    </div>
    <div className="griditem ">
        <a className="gridProjectLink" href="https://www.omegatipsters.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="omegatipsters">
            <img src="img/projects/omegatipsters/logo.svg" alt="omegatipsters " loading="lazy" />
        </a>
    </div>
    <div className="griditem ">
        <a className="gridProjectLink" href="https://www.behance.net/gallery/80993247/EveryGame" target="_blank" rel="nofollow noopener noreferrer" data-project="heberger">
            <img src="img/projects/everygame.png" alt="EveryGame " loading="lazy" />
        </a>
    </div>
    <div className="griditem vertical">
        <a className="gridProjectLink" href="https://www.jetbull.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="jetbull">
            <img src="img/projects/jetbull/logo.svg" alt="jetbull " loading="lazy" />
        </a>
    </div>
    <div className="griditem ">
        <a className="gridProjectLink" href="https://www.casiplay.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="casiplay">
            <img src="img/projects/casiplay/logo.svg" alt="casiplay " loading="lazy" />
        </a>
    </div>
    <div className="griditem ">
        <a className="gridProjectLink" href="https://www.reactcasino.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="reactcasino">
            <img src="img/projects/reactcasino/logo.svg" alt="reactcasino " loading="lazy" />
        </a>
    </div>
    <div className="griditem">
        <a className="gridProjectLink" href="https://www.betbrain.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="betbrain">
            <img src="img/projects/betbrain/logo.svg" alt="betbrain " loading="lazy" />
        </a>
    </div>
</div>
)

export default HomeProjects