import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import HomeProjects from "../components/home-projects"
// import AOS from 'aos';

class IndexPage extends React.Component {
  // constructor(props,context) {
  //   super(props,context);
  //   // AOS.init();
  // }

  render() {
    return (
      <Layout>
        <SEO title="Iliuta Stoica - Frontend Developer" />
        <div className="homeContent">
          <div className="box aboutWrapper">
            <div className="maxWidth">
              <div className="aboutContent">
                <h2 className="pageTitle">About me</h2>
                <p className="contentText ">
                  {" "}
                  Hi, I’m a passionate Frontend Developer, with{" "}
                  {new Date().getFullYear() - 2011}+ years professional
                  experience in the following technologies: React, Angular 9,
                  JavaScript, jQuery, HTML, CSS, LESS, SASS, Parallax,
                  Bootstrap, PHP, WordPress PHP, PWA, AMP. I’m effective with
                  SASS/ jQuery/ Parallax/ Animations and Responsive Design. I'm
                  dedicated, fast learner and solution oriented.
                </p>
                <Link to="/about" className="button" title="read more about me">
                  <span className="buttonText"> read more </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="box latestProjs">
            <div className="maxWidth">
              <div className="projectsContent">
                {" "}
                {/* data-aos="fade-up"*/}
                <h2 className="pageTitle">Latest Projects</h2>
                <p className="contentText">
                  Use the view more button for other projects
                </p>
                <HomeProjects />
                <Link
                  to="/projects"
                  className="button"
                  title="see all of my projects"
                >
                  <span className="buttonText"> view more </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default IndexPage
